import React, { useState } from "react";

import {
  RowSpaceBetween,
  RowStart,
  RowEnd,
  RowCenter,
} from "../../components/general_styled";
import {
  NavbarStyled,
  BodyNavbar,
  LinkWrapper,
  SocialIconStyled,
  SocialWrapperStyled,
  ALinkStyledText,
  BodyLine,
  AnchorBodyLinkStyledText,
  OpenseaIconStyled,
} from "./styled";
import "./style.css";
import Logo from "../../assets/images/mobile/logo.png";
import Hamburger from "../../assets/images/mobile/hamburger.png";
import Close from "../../assets/images/mobile/close.png";
import useWindowDimensions from "../../utils/windowDimensions";
import openseaBlack from "../../assets/images/desktop/opensea-icon-black.png";

import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Navbar(props) {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);

  const onNavClicked = (path) => {
    if (width < 1120) {
      setIsOpen(false);
    }

    //   navigate(path)
  };

  // const Dropdown = ({ label, value, options, onChange }) => {
  //   return (
  //     <label>
  //       {label}
  //       <select value={value} onChange={onChange}>
  //         {options.map((option) => (
  //           <option value={option.value}>{option.label}</option>
  //         ))}
  //       </select>
  //     </label>
  //   );
  // };
  return width < 1280 ? (
    <>
      <NavbarStyled className="mobile">
        <RowStart>
          <img
            src={Logo}
            alt="logo"
            className="ml-30px"
            height={25}
            style={{ height: "25px" }}
          />
        </RowStart>
        <RowEnd>
          <img
            src={isOpen ? Close : Hamburger}
            alt="hamburger"
            className={"mr-30px " + (isOpen && "rotate")}
            height={25}
            style={{ height: "25px" }}
            onClick={() => setIsOpen(!isOpen)}
          />
        </RowEnd>
      </NavbarStyled>

      <BodyNavbar className={isOpen && "open"}>
        {isOpen && (
          <>
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#home"
                onClick={onNavClicked}>
                Home
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#tokyo"
                onClick={onNavClicked}>
                Tokyo
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#kiwami"
                onClick={onNavClicked}>
                Kiwami
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#team"
                onClick={onNavClicked}>
                Team
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/manga"
                onClick={onNavClicked}>
                Manga
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            {/* <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="https://market.kiwami.app/"
                target="new">
                Market
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine /> */}
            {/*
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#strategy"
                onClick={onNavClicked}
              >
                Strategy
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
			*/}
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/fuck">
                FUCK
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#nft"
                onClick={onNavClicked}>
                NFT
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <br />
            <RowCenter style={{ marginBottom: "30px" }}>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://opensea.io/collection/kiwami-genesis");
                  }, 500);
                }}>
                <OpenseaIconStyled src={openseaBlack} />
              </SocialWrapperStyled>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://www.instagram.com/kiwaminft/");
                  }, 500);
                }}>
                <SocialIconStyled icon={brands("instagram")} />
              </SocialWrapperStyled>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://twitter.com/KiwamiNFT");
                  }, 500);
                }}>
                <SocialIconStyled icon={brands("twitter")} />
              </SocialWrapperStyled>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://www.youtube.com/@kiwamiX");
                  }, 500);
                }}>
                <SocialIconStyled icon={brands("youtube")} />
              </SocialWrapperStyled>
            </RowCenter>
          </>
        )}
      </BodyNavbar>
    </>
  ) : (
    <NavbarStyled>
      <RowSpaceBetween>
        <RowStart>
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/#home">
              Home
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/#tokyo">
              Tokyo
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/#kiwami">
              Kiwami
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/#team">
              Team
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/manga">
              Manga
            </ALinkStyledText>
          </LinkWrapper>
          {/* <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="https://market.kiwami.app/"
              target="new">
              Market
            </ALinkStyledText>
          </LinkWrapper> */}
          {/*
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#strategy">
              Strategy
            </ALinkStyledText>
          </LinkWrapper>
		  */}
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/fuck">
              FUCK
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText
              className="hover-2"
              href="/#nft">
              NFT
            </ALinkStyledText>
          </LinkWrapper>
          {/* <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/manga/2">
              Chapter 2
            </ALinkStyledText>
          </LinkWrapper> */}
        </RowStart>
        <RowEnd>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://opensea.io/collection/kiwami-genesis");
              }, 500);
            }}>
            <OpenseaIconStyled src={openseaBlack} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://www.instagram.com/kiwaminft/");
              }, 500);
            }}>
            <SocialIconStyled icon={brands("instagram")} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://twitter.com/KiwamiNFT");
              }, 500);
            }}>
            <SocialIconStyled icon={brands("twitter")} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://www.youtube.com/@kiwamiX");
              }, 500);
            }}>
            <SocialIconStyled icon={brands("youtube")} />
          </SocialWrapperStyled>
        </RowEnd>
      </RowSpaceBetween>
    </NavbarStyled>
  );
}
