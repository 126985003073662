import { BackgroundStyled, ContentStyled } from "./styled";

import {
  HeaderStyled,
  DescStyled,
  DescStyledClub,
  BoxStyled,
} from "../../components/general_styled";
import { ALinkStyledText } from "./styled";
import BgMobile from "../../assets/images/mobile/bgTokyo-mob.jpg";
import BgTokyo from "../../assets/images/desktop/bgTokyo.png";
import useWindowDimensions from "../../utils/windowDimensions";

export default function Tokyo() {
  const { width } = useWindowDimensions();

  return width > 1023 ? (
    <BackgroundStyled
      id="tokyo"
      background={BgTokyo}>
      {" "}
      {/* <Navbar underline="1" /> */}{" "}
      <ContentStyled className="half">
        <HeaderStyled> DISCOVER TOKYO </HeaderStyled>{" "}
        <BoxStyled>
          <DescStyledClub>
            Kiwami work is a retro sci-fi anime set in a near future Tokyo. You
            don't know Tokyo!? No problem, Kiwami is the only web3 anime project
            that brings you the authentic Tokyo, through the{" "}
            <em>Discover Tokyo with Kiwami</em> video series, so everyone can
            enjoy & imagine the background.
            <br />
            <br />
            <br />
            <ALinkStyledText
              className="hover-2"
              href=" https://youtube.com/watch?v=5EmmHmplYk8&list=PL-UEJIxEtCdc5thFXcQ5HiYQblzQ7F1pS&index=1"
              target="_blank">
              Click here
            </ALinkStyledText>
          </DescStyledClub>{" "}
        </BoxStyled>{" "}
      </ContentStyled>{" "}
    </BackgroundStyled>
  ) : (
    <BackgroundStyled
      id="tokyo"
      background={BgMobile}
      className="pt-50px pb-50px">
      {" "}
      {/* <Navbar underline="1" /> */}{" "}
      <ContentStyled className="half">
        <HeaderStyled> DISCOVER TOKYO </HeaderStyled>{" "}
        <BoxStyled>
          <DescStyledClub>
            Kiwami work is a retro sci-fi anime set in a near future Tokyo. You
            don't know Tokyo!? No problem, Kiwami is the only web3 anime project
            that brings you the authentic Tokyo, through the{" "}
            <em>Discover Tokyo with Kiwami</em> video series, so everyone can
            enjoy & imagine the background.
            <br />
            <br />
            <br />
            <ALinkStyledText
              className="hover-2"
              href=" https://youtube.com/watch?v=5EmmHmplYk8&list=PL-UEJIxEtCdc5thFXcQ5HiYQblzQ7F1pS&index=1"
              target="_blank">
              Click here
            </ALinkStyledText>
          </DescStyledClub>{" "}
        </BoxStyled>{" "}
      </ContentStyled>{" "}
    </BackgroundStyled>
  );
}
