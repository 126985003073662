import React from "react";

import {
  ColCenter,
  RowCenter,
  RowEnd,
  RowSpaceBetween,
  RowStart,
} from "../../components/general_styled";
import {
  FooterStyled,
  KiwamiSmall,
  KiwamiBig,
  SocialIconStyled,
  SocialWrapperStyled,
  OpenseaIconStyled,
} from "./styled";

import Diamond from "../../assets/images/desktop/diamond.png";
import Line from "../../assets/images/desktop/line.png";
import useWindowDimensions from "../../utils/windowDimensions";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import openseaBlack from "../../assets/images/desktop/opensea-icon-black.png";

export default function Footer() {
  const { width } = useWindowDimensions();

  return width < 1024 ? (
    <FooterStyled>
      <RowCenter className="w-100">
        <ColCenter>
          <RowCenter className="align-center mb-15px">
            <img
              src={Line}
              className="kiwami-line"
              alt="line"
            />
            <KiwamiBig>{"/// Kiwami ///"}</KiwamiBig>
            <img
              src={Line}
              className="kiwami-line"
              alt="line"
            />
          </RowCenter>
          <RowCenter>
            <SocialWrapperStyled
              className="hover-social"
              onClick={() => {
                setTimeout(() => {
                  window.open("https://opensea.io/collection/kiwami-genesis");
                }, 500);
              }}>
              <OpenseaIconStyled src={openseaBlack} />
            </SocialWrapperStyled>
            <SocialWrapperStyled
              className="hover-social"
              onClick={() => {
                setTimeout(() => {
                  window.open("https://www.instagram.com/kiwaminft/");
                }, 500);
              }}>
              <SocialIconStyled icon={brands("instagram")} />
            </SocialWrapperStyled>
            <SocialWrapperStyled
              className="hover-social"
              onClick={() => {
                setTimeout(() => {
                  window.open("https://twitter.com/KiwamiNFT");
                }, 500);
              }}>
              <SocialIconStyled icon={brands("twitter")} />
            </SocialWrapperStyled>
            <SocialWrapperStyled
              className="hover-social"
              onClick={() => {
                setTimeout(() => {
                  window.open("https://www.youtube.com/@kiwamiX");
                }, 500);
              }}>
              <SocialIconStyled icon={brands("youtube")} />
            </SocialWrapperStyled>
          </RowCenter>
        </ColCenter>
      </RowCenter>
      <div className="kiwami-small">
        <RowEnd className=" align-center">
          <img
            src={Diamond}
            className="img-diamond"
            alt="diamond"
          />
          <KiwamiSmall>Kiwami 2023</KiwamiSmall>
        </RowEnd>
      </div>
    </FooterStyled>
  ) : (
    <FooterStyled>
      <RowSpaceBetween className="w-100">
        <RowStart>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://opensea.io/collection/kiwami-genesis");
              }, 500);
            }}>
            <OpenseaIconStyled src={openseaBlack} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://www.instagram.com/kiwaminft/");
              }, 500);
            }}>
            <SocialIconStyled icon={brands("instagram")} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://twitter.com/KiwamiNFT");
              }, 500);
            }}>
            <SocialIconStyled icon={brands("twitter")} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://www.youtube.com/@kiwamiX");
              }, 500);
            }}>
            <SocialIconStyled icon={brands("youtube")} />
          </SocialWrapperStyled>
        </RowStart>
        <RowCenter className="align-center">
          <img
            src={Line}
            className="kiwami-line"
            alt="line"
          />
          <KiwamiBig>{"/// Kiwami ///"}</KiwamiBig>
          <img
            src={Line}
            className="kiwami-line"
            alt="line"
          />
        </RowCenter>
        <RowEnd className=" align-center">
          <img
            src={Diamond}
            className="img-diamond"
            alt="diamond"
          />
          <KiwamiSmall>Kiwami 2023</KiwamiSmall>
        </RowEnd>
      </RowSpaceBetween>
    </FooterStyled>
  );
}
