import Navbar from "../../../layouts/Navbar";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import styled from "@emotion/styled";

import useWindowDimensions from "../../../utils/windowDimensions";

import { Box, ChakraProvider, Button, Image } from "@chakra-ui/react";

import { HeaderStyled } from "../../../components/general_styled";

import Page1 from "../../../assets/images/desktop/chibi-manga-ch2/01.png";
import Page2 from "../../../assets/images/desktop/chibi-manga-ch2/02.png";
import Page3 from "../../../assets/images/desktop/chibi-manga-ch2/03.png";
import Page4 from "../../../assets/images/desktop/chibi-manga-ch2/04.png";

import Player from "./player.js";

const Header = styled(HeaderStyled)`
  text-align: center !important;
  margin-left: 0px !important;
  font-size: 34px !important;
  font-family: "Akira";
  color: black;
  font-weight: bold;
  margin-bottom: 30px;
`;

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple },
  },
  theme
);

const MOBILE_BREAKPOINT = 768;

// const MOBILE_NAV_HEIGHT = 55;
// const DESKTOP_NAV_HEIGHT = 66;

const BASE_PAGE_WIDTH = 800;

//const TITLE = "AFRO LADY";
const TITLE = "";

const ChibiManga = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= MOBILE_BREAKPOINT;

  // const NAV_HEIGHT = isMobile ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT;
  // const TOP_OFFSET = NAV_HEIGHT;

  const PAGE_WIDTH = isMobile ? "100%" : BASE_PAGE_WIDTH;

  return (
    <ChakraProvider theme={myTheme}>
      <Navbar />
      <Box
        position="absolute"
        backgroundColor="#f1f5f9"
        top={55}
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        flexDirection="column">
        <Box
          position="sticky"
          top={isMobile ? "calc(100vh - 100px)" : "130px"}
          width={isMobile ? "auto" : "100%"}
          paddingLeft={isMobile ? "0" : "40px"}
          display="flex">
          <Player />
        </Box>
        <Box marginTop={isMobile ? "-40px" : "100px"}>
          <Header>{TITLE}</Header>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column">
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page1}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page2}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page3}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page4}
              width={PAGE_WIDTH}
            />
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default ChibiManga;
