import Navbar from "../../layouts/Navbar";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import styled from "@emotion/styled";

import useWindowDimensions from "../../utils/windowDimensions";

import { Box, ChakraProvider, Button, Image } from "@chakra-ui/react";

import { HeaderStyled } from "../../components/general_styled";
import chap10 from "../../assets/images/desktop/manga-ch10/01.png";

import Page2 from "../../assets/images/desktop/manga-ch10/02.png";
import Page3 from "../../assets/images/desktop/manga-ch10/03.png";
import Page4 from "../../assets/images/desktop/manga-ch10/04.png";
import Page5 from "../../assets/images/desktop/manga-ch10/05.png";
import Page6 from "../../assets/images/desktop/manga-ch10/06.png";
import Page7 from "../../assets/images/desktop/manga-ch10/07.png";
import Page8 from "../../assets/images/desktop/manga-ch10/08.png";
import Page9 from "../../assets/images/desktop/manga-ch10/09.png";
import Page10 from "../../assets/images/desktop/manga-ch10/10.png";
import Page11 from "../../assets/images/desktop/manga-ch10/11.png";
import Page12 from "../../assets/images/desktop/manga-ch10/12.png";
import Page13 from "../../assets/images/desktop/manga-ch10/13.png";
import Page14 from "../../assets/images/desktop/manga-ch10/14.png";
import Page15 from "../../assets/images/desktop/manga-ch10/15.png";
import Page16 from "../../assets/images/desktop/manga-ch10/16.png";
import Page17 from "../../assets/images/desktop/manga-ch10/17.png";

import Player from "./player10.js";

const Header = styled(HeaderStyled)`
  text-align: center !important;
  margin-left: 0px !important;
  font-size: 34px !important;
  font-family: "Akira";
  color: black;
  font-weight: bold;
  margin-bottom: 30px;
`;

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple },
  },
  theme
);

const MOBILE_BREAKPOINT = 768;

// const MOBILE_NAV_HEIGHT = 55;
// const DESKTOP_NAV_HEIGHT = 66;

const BASE_PAGE_WIDTH = 800;

//const TITLE = "FREE THE CONSCIOUSNESS";

const Manga = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= MOBILE_BREAKPOINT;

  // const NAV_HEIGHT = isMobile ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT;
  // const TOP_OFFSET = NAV_HEIGHT;

  const PAGE_WIDTH = isMobile ? "100%" : BASE_PAGE_WIDTH;

  const handleClaimClick = () => {
    window.open("https://app.manifold.xyz/c/KiwamiJinRaiga", "_blank");
  };

  return (
    <ChakraProvider theme={myTheme}>
      <Navbar />
      <Box
        position="absolute"
        backgroundColor="#f1f5f9"
        top={55}
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        flexDirection="column">
        <Box
          position="sticky"
          top={isMobile ? "calc(100vh - 100px)" : "130px"}
          width={isMobile ? "auto" : "100%"}
          paddingLeft={isMobile ? "0" : "40px"}
          display="flex">
          <Player />
        </Box>
        <Box marginTop={isMobile ? "-40px" : "100px"}>
          {/* <Header>{TITLE}</Header> */}
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column">
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={chap10}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page2}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page3}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page4}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page5}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page6}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page7}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page8}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page9}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page10}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page11}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page12}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page13}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page14}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page15}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page16}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow="dark-lg"
              src={Page17}
              width={PAGE_WIDTH}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          marginTop={"50px"}
          marginBottom={isMobile ? "150px" : "50px"}>
          <Button
            onClick={handleClaimClick}
            variant="primary"
            size="lg">
            Claim NFT
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Manga;
