import { theme } from "@chakra-ui/pro-theme";
import { extendTheme, Box, ChakraProvider, Image } from "@chakra-ui/react";
import "../gallery/styles/index.css";

import Navbar from "../../layouts/Navbar";
import useWindowDimensions from "../../utils/windowDimensions";

import Character from "../../assets/images/desktop/fuck-anime.png";
import ComingSoon from "../../assets/images/desktop/coming_soon.jpg";

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple },
  },
  theme
);

const MOBILE_BREAKPOINT = 1024;

// const MOBILE_NAV_HEIGHT = 55;
// const DESKTOP_NAV_HEIGHT = 66;

const Fuck = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= MOBILE_BREAKPOINT;

  // const NAV_HEIGHT = isMobile ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT;
  // const TOP_OFFSET = NAV_HEIGHT;

  const PAGE_WIDTH = isMobile && "100%";

  return (
    <ChakraProvider theme={myTheme}>
      <Navbar />
      <Box
        position='absolute'
        backgroundColor='#f1f5f9'
        top={isMobile ? 90 : 55}
        left='0'
        right='0'
        display='flex'
        alignItems='center'
        flexDirection='column'>
        <Box marginTop={isMobile ? "-40px" : "100px"}>
          <Box
            display='flex'
            alignItems='center'
            height={"auto"}
            gap={14}
            flexDirection={isMobile ? "column" : "row"}>
            <a
              href='https://twitter.com/The_Fuck_Coin'
              target='_blank'>
              <Image
                style={{ marginBottom: 30, cursor: "pointer" }}
                src={Character}
                width={isMobile ? PAGE_WIDTH : 350}
              />
            </a>
            <Image
              style={{ marginBottom: 30 }}
              src={ComingSoon}
              width={isMobile ? PAGE_WIDTH : 900}
            />
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Fuck;
