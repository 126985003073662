import { useState } from "react";
import {
  ContentStyled,
  HeaderStyled,
  CanvasButton,
  ButtonCircleStyled,
  RowSpaceBetween,
  ColStart,
  ColCenter,
  BoxStyled,
  ArrowsRightStyled,
  ArrowsLeftStyled,
  Space,
} from "../../components/general_styled";
import { BackgroundStyled, ImageTeamStyled, DescTeamStyled } from "./styled";
import BgTeam from "../../assets/images/desktop/bg_team.jpg";
import BgMobileTeam from "../../assets/images/mobile/bg_team.jpg";
import BtnCircle from "../../assets/images/desktop/btn_circle.png";
import ImageViva from "../../assets/images/desktop/viva.png";
import ImageMil from "../../assets/images/desktop/mil.png";
import ImageMadao from "../../assets/images/desktop/madao.png";
//import ImageAru from "../../assets/images/desktop/aru.png"
//import ImageScythe from "../../assets/images/desktop/scythe.png"
import ImageFedup from "../../assets/images/desktop/fedup.png";
import ImageNeko from "../../assets/images/desktop/neko.png";
// import ImageMouseDev from "../../assets/images/desktop/mousedev.png"
// import ImageChef from "../../assets/images/desktop/chef.png"
import useWindowDimensions from "../../utils/windowDimensions";

export default function Team() {
  const { width } = useWindowDimensions();
  const [selected, setSelected] = useState(0);

  const data = [
    {
      image: ImageViva,
      name: "VivaTokyo",
      desc1: "Founder & Creative Director. ",
      desc2: "Cheeth Hat Master. ",
      desc3: "Find me at an izakaya in Harajuku 原宿.",
    },
    {
      image: ImageMil,
      name: "Mil",
      desc1: "Chief Design Coordinator. ",
      desc2: "Vintage Couture. ",
      desc3: "Out and about in Omotesando 表参道.",
    },
    {
      image: ImageMadao,
      name: "MadAo",
      desc1: "Lead Artist. ",
      desc2: "Keeping a low profile. ",
      desc3: "Fish watching at the local marine park.",
    },
    {
      image: ImageNeko,
      name: "Neko",
      desc1: "Event Coordinator.",
      desc2: "Challenge the norm and make waves in the status quo.",
      desc3: "Cruising through the Twitter streets.",
    },
  ];

  const onButtonPrevious = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    } else {
      setSelected(data.length - 1);
    }
  };

  const onButtonNext = () => {
    if (selected < data.length - 1) {
      setSelected(selected + 1);
    } else {
      setSelected(0);
    }
  };

  return width > 1023 ? (
    <BackgroundStyled
      id="team"
      background={BgTeam}>
      {/* <Navbar underline="1" /> */}
      <ContentStyled>
        <RowSpaceBetween className="w-100">
          <ColStart style={{ width: width < 1439 ? "50%" : "50%" }}>
            <HeaderStyled className={"mt-0px"}>TEAM</HeaderStyled>
            <HeaderStyled
              className={
                " montserrat text-team " +
                (width < 1439 ? "mt-10px" : "mt-30px")
              }>
              {data[selected].name}
            </HeaderStyled>
            <BoxStyled
              style={{
                minHeight: "16rem",
              }}>
              <DescTeamStyled>
                {data[selected].desc1}
                <br />
                <br />
                {data[selected].desc2}
                <br />
                <br />
                {data[selected].desc3}
                <br />
                <br />
              </DescTeamStyled>
            </BoxStyled>
            <CanvasButton
              className="team"
              style={{ marginTop: 10 }}>
              <Space></Space>
              <ArrowsLeftStyled onClick={onButtonPrevious}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </ArrowsLeftStyled>
              <ButtonCircleStyled src={BtnCircle} />
              <ArrowsRightStyled onClick={onButtonNext}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </ArrowsRightStyled>
            </CanvasButton>
          </ColStart>
          <ImageTeamStyled src={data[selected].image} />
        </RowSpaceBetween>
      </ContentStyled>
    </BackgroundStyled>
  ) : (
    <BackgroundStyled
      id="team"
      background={BgMobileTeam}
      className={"pt-50px pb-50px"}>
      {/* <Navbar underline="1" /> */}
      <ContentStyled>
        <ColCenter className="w-100">
          <HeaderStyled className={"mt-0px akira team"}>TEAM</HeaderStyled>
          <ImageTeamStyled src={data[selected].image} />
          <ColCenter>
            <HeaderStyled className="team text-team">
              {data[selected].name}
            </HeaderStyled>
            <BoxStyled
              style={{
                minHeight: width > 767 ? "10vh" : "7rem",
              }}>
              <DescTeamStyled>
                {data[selected].desc1}
                <br />
                <br />
                {data[selected].desc2}
                <br />
                <br />
                {data[selected].desc3}
                <br />
              </DescTeamStyled>
            </BoxStyled>
            <CanvasButton className="team">
              <ArrowsLeftStyled onClick={onButtonPrevious}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </ArrowsLeftStyled>
              <ButtonCircleStyled src={BtnCircle} />
              <ArrowsRightStyled onClick={onButtonNext}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </ArrowsRightStyled>
            </CanvasButton>
          </ColCenter>
        </ColCenter>
      </ContentStyled>
    </BackgroundStyled>
  );
}
