import Navbar from "../../layouts/Navbar";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import styled from "@emotion/styled";

import useWindowDimensions from "../../utils/windowDimensions";

import { Box, ChakraProvider, Button, Image } from "@chakra-ui/react";

import { HeaderStyled } from "../../components/general_styled";
import chap9 from "../../assets/images/desktop/manga-ch9/Lamda Vs Ron-1N.png";

import Page2 from "../../assets/images/desktop/manga-ch9/LAMDA2_002.jpg";
import Page3 from "../../assets/images/desktop/manga-ch9/LAMDA2_003.jpg";
import Page4 from "../../assets/images/desktop/manga-ch9/LAMDA2_004.jpg";
import Page5 from "../../assets/images/desktop/manga-ch9/LAMDA2_005.jpg";
import Page6 from "../../assets/images/desktop/manga-ch9/LAMDA2_006.jpg";
import Page7 from "../../assets/images/desktop/manga-ch9/LAMDA2_007.jpg";
import Page8 from "../../assets/images/desktop/manga-ch9/LAMDA2_008.jpg";
import Page9 from "../../assets/images/desktop/manga-ch9/LAMDA2_009.jpg";
import Page10 from "../../assets/images/desktop/manga-ch9/LAMDA2_010.jpg";
import Page11 from "../../assets/images/desktop/manga-ch9/LAMDA2_011.jpg";
import Page12 from "../../assets/images/desktop/manga-ch9/LAMDA2_012.jpg";
import Page13 from "../../assets/images/desktop/manga-ch9/LAMDA2_013.jpg";
import Page14 from "../../assets/images/desktop/manga-ch9/LAMDA2_014.jpg";
import Page15 from "../../assets/images/desktop/manga-ch9/LAMDA2_015.jpg";
import Page16 from "../../assets/images/desktop/manga-ch9/LAMDA2_016.jpg";
import Page17 from "../../assets/images/desktop/manga-ch9/LAMDA2_017.jpg";
import Page18 from "../../assets/images/desktop/manga-ch9/LAMDA2_018.jpg";
import Page19 from "../../assets/images/desktop/manga-ch9/LAMDA2_019.jpg";

import Player from "./player9.js";

const Header = styled(HeaderStyled)`
  text-align: center !important;
  margin-left: 0px !important;
  font-size: 34px !important;
  font-family: "Akira";
  color: black;
  font-weight: bold;
  margin-bottom: 30px;
`;

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple },
  },
  theme
);

const MOBILE_BREAKPOINT = 768;

// const MOBILE_NAV_HEIGHT = 55;
// const DESKTOP_NAV_HEIGHT = 66;

const BASE_PAGE_WIDTH = 800;

//const TITLE = "FREE THE CONSCIOUSNESS";
const TITLE = "";

const Manga = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= MOBILE_BREAKPOINT;

  // const NAV_HEIGHT = isMobile ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT;
  // const TOP_OFFSET = NAV_HEIGHT;

  const PAGE_WIDTH = isMobile ? "100%" : BASE_PAGE_WIDTH;

  const handleClaimClick = () => {
    window.open("https://app.manifold.xyz/c/LamdaVsRon-1N", "_blank");
  };

  return (
    <ChakraProvider theme={myTheme}>
      <Navbar />
      <Box
        position='absolute'
        backgroundColor='#f1f5f9'
        top={55}
        left='0'
        right='0'
        display='flex'
        alignItems='center'
        flexDirection='column'>
        <Box
          position='sticky'
          top={isMobile ? "calc(100vh - 100px)" : "130px"}
          width={isMobile ? "auto" : "100%"}
          paddingLeft={isMobile ? "0" : "40px"}
          display='flex'>
          <Player />
        </Box>
        <Box marginTop={isMobile ? "-40px" : "100px"}>
          <Header>{TITLE}</Header>
          <Box
            display='flex'
            alignItems='center'
            flexDirection='column'>
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={chap9}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page2}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page3}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page4}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page5}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page6}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page7}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page8}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page9}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page10}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page11}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page12}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page13}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page14}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page15}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page16}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page17}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page18}
              width={PAGE_WIDTH}
            />
            <Image
              style={{ marginBottom: 30 }}
              boxShadow='dark-lg'
              src={Page19}
              width={PAGE_WIDTH}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          marginTop={"50px"}
          marginBottom={isMobile ? "150px" : "50px"}>
          <Button
            onClick={handleClaimClick}
            variant='primary'
            size='lg'>
            Claim NFT
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Manga;
