import React, { useState } from "react";
import { BackgroundStyled } from "../Strategy/styled";
import {
  ContentStyled,
  HeaderStyled,
  DescStyled,
  BoxStyled,
  SubHeaderStyled,
  CanvasButton,
  ButtonCircleStyled,
  HyperLinkStyled,
  ArrowsRightStyled,
  ArrowsLeftStyled,
  Space,
} from "../../components/general_styled";
// import BgStrategy from "../../assets/images/desktop/bg_strategy.jpg"
import BgFaq from "../../assets/images/desktop/bg_faq.png";
import BgFaqMobile from "../../assets/images/mobile/bg_faq_mobile.png";
// import BgStrategyMobile from "../../assets/images/mobile/bg_strategy.jpg"
import BtnCircle from "../../assets/images/desktop/btn_circle.png";
import useWindowDimensions from "../../utils/windowDimensions";
// import BgMobileTeam from "../../assets/images/mobile/bg_team.jpg"

export default function NFT() {
  const { width } = useWindowDimensions();
  const [selected, setSelected] = useState(0);

  const data = [
    <>
      <SubHeaderStyled>Main Collection</SubHeaderStyled>
      <DescStyled>
        <HyperLinkStyled
          className="hover-2"
          onClick={() => {
            window.open("https://opensea.io/collection/kiwami-genesis");
          }}>
          Kiwami
        </HyperLinkStyled>
      </DescStyled>
    </>,
    <>
      <SubHeaderStyled>Pixel Art Collection</SubHeaderStyled>
      <DescStyled>
        <HyperLinkStyled
          className="hover-2"
          onClick={() => {
            window.open("https://opensea.io/collection/kiwamix");
          }}>
          KiwamiX
        </HyperLinkStyled>
      </DescStyled>
    </>,
    <>
      <SubHeaderStyled>Art Collection</SubHeaderStyled>
      <DescStyled>
        <HyperLinkStyled
          className="hover-2"
          onClick={() => {
            window.open("https://opensea.io/collection/kiwami-art");
          }}>
          Kiwami Art
        </HyperLinkStyled>
      </DescStyled>
    </>,
    <>
      <SubHeaderStyled>Manga Collection</SubHeaderStyled>
      <DescStyled>
        <HyperLinkStyled
          className="hover-2"
          onClick={() => {
            window.open("https://opensea.io/collection/kiwami-manga");
          }}>
          Kiwami Manga
        </HyperLinkStyled>
      </DescStyled>
    </>,
  ];

  const onButtonPrevious = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    } else {
      setSelected(data.length - 1);
    }
  };

  const onButtonNext = () => {
    if (selected < data.length - 1) {
      setSelected(selected + 1);
    } else {
      setSelected(0);
    }
  };

  return (
    <BackgroundStyled
      id="nft"
      background={width > 1023 ? BgFaq : BgFaqMobile}
      className={width > 1023 ? "" : "pt-50px pb-50px"}>
      {/* <Navbar underline="1" /> */}
      <ContentStyled className="half">
        <HeaderStyled>NFT</HeaderStyled>
        <BoxStyled className="strategy">
          <DescStyled
            style={{
              minHeight: width > 768 ? "10rem" : "8rem",
            }}>
            {data[selected]}
          </DescStyled>
        </BoxStyled>
        <CanvasButton className="strategy">
          <Space></Space>
          <ArrowsLeftStyled onClick={onButtonPrevious}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </ArrowsLeftStyled>
          <ButtonCircleStyled src={BtnCircle} />
          <ArrowsRightStyled onClick={onButtonNext}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </ArrowsRightStyled>
        </CanvasButton>
      </ContentStyled>
    </BackgroundStyled>
  );
}
