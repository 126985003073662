import Navbar from "../../layouts/Navbar";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import styled from "@emotion/styled";

import useWindowDimensions from "../../utils/windowDimensions";

import {
  Box,
  ChakraProvider,
  // Button,
  Image,
} from "@chakra-ui/react";

import { HeaderStyled } from "../../components/general_styled";

import manga from "../../assets/images/desktop/mangaMeta/KiwamiManga.png";
import mangaPink from "../../assets/images/desktop/mangaMeta/KiwamiMangaPink.png";
import chap1 from "../../assets/images/desktop/manga/1.jpeg";
import chap2 from "../../assets/images/desktop/manga-ch2/SamuraiNeko.jpg";
import chap3 from "../../assets/images/desktop/manga-ch3/Mil_0013.jpg";
import chap4 from "../../assets/images/desktop/manga-ch4/00.jpg";
import chap5 from "../../assets/images/desktop/manga-ch5/01.png";
import chap6 from "../../assets/images/desktop/manga-ch6/01.png";
import chap7 from "../../assets/images/desktop/manga-ch7/01.png";
import chap8 from "../../assets/images/desktop/manga-ch8/01.jpg";
import chap9 from "../../assets/images/desktop/manga-ch9/Lamda Vs Ron-1N.png";
import chap10 from "../../assets/images/desktop/manga-ch10/01.png";
import chap11 from "../../assets/images/desktop/manga-ch11/01.jpg";

// import Player from "./player.js";
import ChibiManga1 from "../../assets/images/desktop/chibi-manga-ch1/01.png";
import ChibiManga2 from "../../assets/images/desktop/chibi-manga-ch2/01.png";
import ChibiManga3 from "../../assets/images/desktop/chibi-manga-ch3/01.png";
import ChibiManga4 from "../../assets/images/desktop/chibi-manga-ch4/01.png";
import ChibiManga5 from "../../assets/images/desktop/chibi-manga-ch5/01.png";

const Header = styled(HeaderStyled)`
  text-align: center !important;
  margin: 0px !important;
  font-size: 12px !important;
  font-family: "Akira";
  color: black;
  font-weight: bold;
`;
const HeaderImg = styled.div`
  display: flex;
  position: sticky;
  margin-left: auto;
  margin-right: auto;
`;

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple },
  },
  theme
);

const MOBILE_BREAKPOINT = 768;

// const MOBILE_NAV_HEIGHT = 55;
// const DESKTOP_NAV_HEIGHT = 66;

const BASE_PAGE_WIDTH = 300;

// const TITLE = "Chapter 1: The Story of Hime"
const TITLE_CHAP_1 = "The Story of Hime";
const TITLE_CHAP_2 = "Samurai Neko";
const TITLE_CHAP_3 = "AFRO LADY";
const TITLE_CHAP_4 = "TRUST THE SYSTEM";
const TITLE_CHAP_5 = "Free the Consciousnesses";
const TITLE_CHAP_6 = "SCARS OF WAR";
const TITLE_CHAP_7 = "NARCONET: DIGITAL RETALIATION";
const TITLE_CHAP_8 = "Virulent Tides";
const TITLE_CHAP_9 = "RON1N";
const TITLE_CHAP_10 = "The Radioactive Thunder";
const TITLE_CHAP_11 = "Redemption's Toll";

const CHIBI_TITLE_CHAP_1 = "WEB2 vs WEB3";
const CHIBI_TITLE_CHAP_2 = "SELF-CUSTODY";
const CHIBI_TITLE_CHAP_3 = "WE ARE ALL ONE";
const CHIBI_TITLE_CHAP_4 = "A BETTER SYSTEM";
const CHIBI_TITLE_CHAP_5 = "The Currency of AI";

const Manga = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= MOBILE_BREAKPOINT;

  //   const NAV_HEIGHT = isMobile ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT;
  //   const TOP_OFFSET = NAV_HEIGHT;

  const PAGE_WIDTH = isMobile ? "80%" : BASE_PAGE_WIDTH;

  return (
    <ChakraProvider theme={myTheme}>
      <Navbar />
      <Box
        backgroundColor="#f1f5f9"
        marginTop={55}
        display="flex"
        alignItems="center"
        flexDirection="column">
        <HeaderImg>
          <img
            style={{ marginTop: 30 }}
            boxShadow="dark-lg"
            src={manga}
            width={100}
            alt="manga_img"
          />{" "}
        </HeaderImg>
        {/* <Box
          position="sticky"
          top={isMobile ? "calc(100vh - 100px)" : "130px"}
          width={isMobile ? "auto" : "100%"}
          paddingLeft={isMobile ? "0" : "40px"}
          display="flex"
        >
          <Player />
        </Box> */}
        <Box marginTop={"50px"}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}>
            <Box
              display="flex"
              alignItems="center"
              style={{ paddingBottom: 45 }}
              flexDirection="column">
              <Header>{TITLE_CHAP_1}</Header>
              <a href="manga/1">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap1}
                  width={PAGE_WIDTH}
                />
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_2}</Header>
              <a href="manga/2">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap2}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_3}</Header>
              <a href="manga/3">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap3}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_4}</Header>
              <a href="manga/4">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap4}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_5}</Header>
              <a href="manga/5">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap5}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_6}</Header>
              <a href="manga/6">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap6}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_7}</Header>
              <a href="manga/7">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap7}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_8}</Header>
              <a href="manga/8">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap8}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_9}</Header>
              <a href="manga/9">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap9}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_10}</Header>
              <a href="manga/10">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap10}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{TITLE_CHAP_11}</Header>
              <a href="manga/11">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={chap11}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Second Manga Group*/}
      <Box
        backgroundColor="#f1f5f9"
        display="flex"
        alignItems="center"
        flexDirection="column">
        <HeaderImg>
          <img
            style={{ marginTop: 30 }}
            boxShadow="dark-lg"
            src={mangaPink}
            width={100}
            alt="manga_img"
          />{" "}
        </HeaderImg>
        <Box marginTop={"50px"}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}>
            <Box
              display="flex"
              alignItems="center"
              style={{ paddingBottom: 45 }}
              flexDirection="column">
              <Header>{CHIBI_TITLE_CHAP_1}</Header>
              <a href="chibimanga/1">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={ChibiManga1}
                  width={PAGE_WIDTH}
                />
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{CHIBI_TITLE_CHAP_2}</Header>
              <a href="chibimanga/2">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={ChibiManga2}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              style={{ paddingBottom: 45 }}>
              <Header>{CHIBI_TITLE_CHAP_3}</Header>
              <a href="chibimanga/3">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={ChibiManga3}
                  width={PAGE_WIDTH}
                />{" "}
              </a>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={isMobile ? "column" : "row"}>
            <Box
              display="flex"
              alignItems="center"
              style={{ paddingBottom: 45 }}
              flexDirection="column">
              <Header>{CHIBI_TITLE_CHAP_4}</Header>
              <a href="chibimanga/4">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={ChibiManga4}
                  width={PAGE_WIDTH}
                />
              </a>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ paddingBottom: 45 }}
              flexDirection="column">
              <Header>{CHIBI_TITLE_CHAP_5}</Header>
              <a href="chibimanga/5">
                <Image
                  style={{
                    marginLeft: 45,
                    marginRight: 45,
                  }}
                  boxShadow="dark-lg"
                  src={ChibiManga5}
                  width={PAGE_WIDTH}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Manga;
